import { ReactNode, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface Props {
  textToCopy: string;
  value: string;
  modifiedNode?: ReactNode;
  copyIcon?: ReactNode;
}

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  "& .MuiSvgIcon-root": {
    fontSize: ".9rem",
    marginTop: "0",
    marginLeft: theme.spacing(0.7),
  },
}));

export default function CopyClip({
  textToCopy,
  value,
  modifiedNode,
  copyIcon,
}: Props) {
  const [copied, setCopied] = useState<boolean>(false);

  const handlerCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 800);
  };

  return (
    <CopyToClipboard text={textToCopy} onCopy={handlerCopy}>
      <Wrapper>
        {modifiedNode ?? <Typography variant="body2">{value}</Typography>}
        {copied ? (
          <CheckCircleIcon sx={{ marginTop: 3 }} />
        ) : (
          (copyIcon ?? <FileCopyIcon color="primary" sx={{ marginTop: 3 }} />)
        )}
      </Wrapper>
    </CopyToClipboard>
  );
}
