import { Children, ReactNode } from "react";
import Stack, { StackProps } from "@mui/material/Stack";
import { Grid2, Grid2Props, useTheme } from "@mui/material";

interface StackRowProps extends StackProps {
  children: ReactNode;
}
interface CGridProps {
  children: ReactNode;
  containerProps?: Grid2Props;
  itemProps?: Grid2Props;
}

export function StackRow({ children, ...rest }: StackRowProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      flex={1}
      {...rest}
    >
      {children}
    </Stack>
  );
}

export function CGrid({ children, itemProps, containerProps }: CGridProps) {
  return (
    <Grid2 container {...containerProps}>
      {Children.map(children, (child) => (
        <Grid2 {...itemProps}>{child}</Grid2>
      ))}
    </Grid2>
  );
}
