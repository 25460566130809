import { Components } from '@mui/material/styles';

export default function InputBase(): Record<'MuiInputBase', Components['MuiInputBase']> {
  return {
    MuiInputBase: {
      defaultProps: {},
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
  };
}
