import { motion } from "framer-motion";
import { MouseEvent, forwardRef, ForwardedRef, ComponentProps } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface IButtonProps extends ButtonProps {
  isLoading?: boolean;
  loadingProps?: CircularProgressProps;
}

function ButtonInd(
  {
    children,
    onClick,
    isLoading = false,
    loadingProps,
    title,
    disabled,
    ...res
  }: IButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick?.(e);
  };

  return (
    <Button
      ref={ref}
      disabled={isLoading || disabled}
      onClick={handleClick}
      variant="contained"
      size="large"
      title={title}
      sx={{
        display: "inline-flex",
        gap: 1,
        background: `linear-gradient(rgba(84, 197, 113,0.7), rgba(84, 197, 113,0.4)) padding-box, linear-gradient(to right, #00FFA3, #DC1FFF) border-box`,
        border: "3px solid transparent", // Makes the gradient border visible
        borderRadius: 2,
        transition: "box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: "0 0 10px 4px rgba(84, 197, 113, 0.8)", // Optional glow on hover
        },
      }}
      {...res}
    >
      {children}
      {title && <HelpOutlineIcon fontSize="medium" />}
      {isLoading && (
        <CircularProgress size={16} sx={{ ml: 1 }} {...loadingProps} />
      )}
    </Button>
  );
}

export const ButtonIndicator = forwardRef(ButtonInd);
export const MotionButtonIndicator = motion.create(ButtonIndicator);
export type MotionButtonIndicatorProps = ComponentProps<
  typeof MotionButtonIndicator
>;
