import { Components, Theme } from '@mui/material/styles';
import { getBorder } from '../../utils/styles';

export default function MuiFilledInput(theme: Theme): Record<'MuiFilledInput', Components['MuiFilledInput']> {
	return {
		MuiFilledInput: {
			defaultProps: {
				disableUnderline: true,
			},
			styleOverrides: {
				root: {
					border: getBorder(theme.palette.grey[200]),
					fontWeight: 500,
					backgroundColor: theme.palette.grey[100],
					'&.Mui-focused': {
						backgroundColor: theme.palette.grey[100],
					},
					'&:hover': {
						backgroundColor: theme.palette.grey[100],
					},
					'&.Mui-error': {
						color: theme.palette.error.main,
					},
				},
			},
		},
	};
}
