import { Components, Theme } from '@mui/material/styles';

export default function ButtonGroup(
  theme: Theme,
): Record<'MuiButtonGroup', Components['MuiButtonGroup']> {
  return {
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 1.3),
          backgroundColor: theme.palette.grey[50],
        },
      },
    },
  };
}
