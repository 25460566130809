export async function getWASMUint8Array() {
  const wasmUrl = "/contracts/wasms/public_mint_call.wasm"; // Path relative to the public folder

  // Fetch the WASM file
  const response = await fetch(wasmUrl);

  // Check for errors
  if (!response.ok) {
    throw new Error(`Failed to fetch WASM file: ${response.statusText}`);
  }

  // Convert to ArrayBuffer
  const arrayBuffer = await response.arrayBuffer();

  // Convert ArrayBuffer to Uint8Array
  return new Uint8Array(arrayBuffer);
}
function toMotes(csprs: number) {
  return (csprs * 1e9).toString();
}

export const GAS = {
  INSTALL: toMotes(140),
  FREE_MINT: toMotes(20),
  NATIVE_MINT: toMotes(20),
  SET_WHITELIST: toMotes(2),
  SET_CONFIG: toMotes(20),
};
