import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { CsprClickInitOptions } from "@make-software/csprclick-core-client";
import { CONTENT_MODE } from "@make-software/csprclick-core-types";
import { ClickProvider, CsprClickThemes } from "@make-software/csprclick-ui";
import "./index.css";
import ThemeCustomization from "themes";
import { RouterProvider } from "react-router-dom";
import { router } from "router";
import { ThemeProvider } from "styled-components";
import { WalletContext } from "contexts/WalletContext";
import { networkName } from "constants/bootEnvironment";
import { TorusContext } from "contexts/TorusContext";
import { NotificationSystem } from "components/Notification/NotificationSystem";
import { MintContext } from "contexts/MintContext";
import { QueryClientProvider } from "@tanstack/react-query";
import { reactQueryClient } from "data/getReactQueryClient";
import reportWebVitals from "reportWebVitals";
// import { DEV_ENVIRONMENT } from "constants/main";

console.log("network name...", networkName);

const clickOptions: CsprClickInitOptions = {
  appName: "ApocNFT",
  appId: process.env.REACT_APP_CSPR_APP_ID || "",
  // appId: DEV_ENVIRONMENT
  //   ? "csprclick-template"
  //   : "ba5c6810-58f5-427e-940d-732b7bd3",
  contentMode: CONTENT_MODE.IFRAME,
  providers: [
    "casper-wallet",
    "ledger",
    "torus-wallet",
    "casperdash",
    "metamask-snap",
    "casper-signer",
  ],
  chainName: networkName,
};

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ThemeCustomization>
      <ClickProvider options={clickOptions}>
        <ThemeProvider theme={CsprClickThemes.dark}>
          <WalletContext>
            <MintContext>
              <QueryClientProvider client={reactQueryClient}>
                <TorusContext>
                  <NotificationSystem />
                  <RouterProvider router={router} />
                </TorusContext>
              </QueryClientProvider>
            </MintContext>
          </WalletContext>
        </ThemeProvider>
      </ClickProvider>
    </ThemeCustomization>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
