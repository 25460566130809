/**
 * @description Utility function to generate an ID from a title with spaces and additionalString
 */
export default function generateId(
  title: string,
  additionalString?: string
): string {
  // Remove spaces and convert to lowercase
  const id = title.replace(/\s+/g, "-").toLowerCase();
  return additionalString ? `${id}-${additionalString}` : id;
}
