import BigNumber from "bignumber.js";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { NODE_ADDRESS, NotificationType } from "../../constants";

import {
  initialConfigState,
  ConfigReducer,
  ConfigActions,
} from "../../reducers/ConfigReducers";

import { networkName } from "../../constants/bootEnvironment";

import { Wallet, Client as CasperClient } from "commons/wallet";
import { log } from "utils";

import { ConfigState } from "../../reducers/ConfigReducers";
import { notificationStore } from "../../store/store";
import {
  ClickUI,
  ThemeModeType,
  TopBarSettings,
  useClickRef,
} from "@make-software/csprclick-ui";
import { ClickWallet } from "../../commons/wallet/ClickWallet";
import store from "store2";
import useConnectionPopUp from "hooks/useConnectionPopUp";
import { formatAmount } from "utils/formatting";

const NETWORK_NAME = networkName;
export const casperClient = new CasperClient(NETWORK_NAME, NODE_ADDRESS);

export interface WalletContextProps {
  onConnectWallet?: (ignoreError?: boolean) => Promise<void>;
  onDisconnectWallet?: () => Promise<void>;
  walletState: ConfigState;
  isConnected?: boolean;
  setShowConnectionPopup: (show: boolean) => void;
  showConnectionPopup: boolean;
  openState: boolean;
  handleOpenState: (val: boolean) => () => void;
  isWhitelistedWallet?: boolean;
  openWalletConnect: boolean;
  handleOpenWalletConnect: () => void;
  nftCount: number;
  setNftCount: (count: number) => void;
  nftBalance: string;
  loadingBalance: boolean;
  setLoadingBalance: (loading: boolean) => void;
  mintCost: string;
  walletLoading: boolean;
}

export const WalletProviderContext = createContext<WalletContextProps>(
  {} as any
);

/**
 * Return type for GetStatus
 */
export type StatusResponseType = {
  // network token balance of the account
  balance: BigNumber;
  // uref of the main purse
  mainPurse: string;
  // is the wallet whitelisted
  isWhitelisted: boolean;
};

/**
 * Get the balance and main purse of the wallet
 *
 * @param wallet Wallet whose account is being used
 * @returns the balance and make purse uref
 */
export async function getStatus(wallet: Wallet): Promise<StatusResponseType> {
  const balance = await casperClient.getBalance(wallet);
  const isWhitelisted = await casperClient.getIsWhiteListed(wallet);
  const mainPurse = await casperClient.getMainPurse(wallet);
  return { balance, isWhitelisted, mainPurse: mainPurse || "" };
}

/**
 *
 * @param wallet
 * @returns either connected wallet is whitelisted or not
 */
export async function getIsWhiteListed(wallet: Wallet): Promise<{
  isWhitelisted: boolean;
  balance: BigNumber;
}> {
  const balance = await casperClient.getBalance(wallet);
  const isWhitelisted = await casperClient.getIsWhiteListed(wallet);
  return { balance, isWhitelisted };
}

/**
 * @returns the cost of minting an NFT
 */
export async function getMintCost(): Promise<string> {
  const cost = await casperClient.getMintCost("1");
  return formatAmount(cost, 9);
}

/**
 *
 * @param wallet
 * @returns the balance of the wallet in terms of NFTs
 */
export async function getNFTBalance(wallet: Wallet): Promise<string> {
  return await casperClient.getNFTBalance(wallet);
}

export const WalletContext = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(ConfigReducer, initialConfigState);
  const [mainPurseFound, setMainPurseFound] = useState(false); // To track if mainPurse is found
  const [isOpenWalletModal, setIsOpenWalletModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nftCount, setNftCount] = useState<number>(1);
  const [nftBalance, setNftBalance] = useState<string>("0");
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);
  const [mintCost, setMintCost] = useState<string>("0");
  const [walletLoading, setWalletLoading] = useState(false);

  const handleWalletModal = useCallback(
    (_state: boolean) => () => setIsOpenWalletModal(_state),
    []
  );

  const handleOpenWalletConnect = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const { updateNotification } = notificationStore();
  const { showConnectionPopup, setShowConnectionPopup } = useConnectionPopUp();

  const clickRef = useClickRef();

  const onConnectWallet = async (ignoreError = false): Promise<void> => {
    setWalletLoading(true);
    if (isConnected) {
      return;
    }

    try {
      clickRef.signIn();
      setWalletLoading(false);
    } catch (err: any) {
      log.error(`onConnectWallet error: ${err}`);
      if (ignoreError) {
        return;
      }

      if (err.message.includes("make sure you have the Signer installed")) {
        updateNotification({
          type: NotificationType.Error,
          title: "This wallet is not installed.",
          subtitle: "",
          show: true,
          isOnlyNotification: true,
          timeToClose: 5000,
        });
        return;
      }

      if (err.message === "main purse does not exist") {
        updateNotification({
          type: NotificationType.Error,
          title: "Main purse does not exist, send CSPR to your wallet first",
          subtitle: "",
          show: true,
          isOnlyNotification: true,
          timeToClose: 5000,
        });
        return;
      }
      // TODO: Casper Wallet is locked
      if (err.message === "Wallet is locked.") {
        updateNotification({
          type: NotificationType.Error,
          title: "Wallet is locked",
          subtitle: "Please unlock your wallet first",
          show: true,
          isOnlyNotification: true,
          timeToClose: 5000,
        });
        return;
      }

      if (err.message.includes("Please install the Casper")) {
        updateNotification({
          type: NotificationType.Error,
          title: "This wallet is not installed.",
          subtitle: err.message,
          show: true,
          isOnlyNotification: true,
          timeToClose: 5000,
        });
        return;
      }

      updateNotification({
        type: NotificationType.Error,
        title: "Ooops we have an error",
        subtitle: "",
        show: true,
        isOnlyNotification: true,
        timeToClose: 5000,
      });

      setWalletLoading(false);
    }
  };

  const { isConnected } = state;

  useEffect(() => {
    let intervalId: any;

    const fetchMainPurse = async () => {
      try {
        const w = new ClickWallet(NETWORK_NAME);
        console.log("findin main purse...");
        const { mainPurse, balance, isWhitelisted } = await getStatus(w);
        console.log("mainPurse...", mainPurse);
        console.log("balance here...", balance.toString());
        if (mainPurse) {
          setMainPurseFound(true); // Stop future fetches
          dispatch({
            type: ConfigActions.SELECT_MAIN_PURSE,
            payload: {
              mainPurse,
              balance: balance.toString(),
              isWhitelistedWallet: isWhitelisted,
            },
          });
        }
      } catch (error) {
        console.error("Failed to fetch main purse:", error);
      }
    };

    if (!mainPurseFound) {
      fetchMainPurse(); // Initial fetch
      intervalId = setInterval(fetchMainPurse, 30000); // Fetch every 30 seconds if not found
    }

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [mainPurseFound, dispatch]);

  useEffect(() => {
    const fetchNFTBalance = async () => {
      try {
        const w = new ClickWallet(NETWORK_NAME);
        const nftBalance = await getNFTBalance(w);
        if (nftBalance) {
          setNftBalance(nftBalance);
        }
      } catch (error) {
        console.error("Failed to fetch NFT balance:", error);
      }
    };

    fetchNFTBalance();
  }, [getNFTBalance]);

  useEffect(() => {
    clickRef?.on("csprclick:signed_in", async (evt) => {
      setWalletLoading(true);
      setLoadingBalance(true);
      store.set("wallet_provider", evt.account.provider);
      store.set("cw-pubk", evt.account.public_key);

      const w = new ClickWallet(NETWORK_NAME);
      w.setClickRef(clickRef);
      w.connect();

      const cost = await getMintCost();
      setMintCost(cost);
      const { isWhitelisted, balance } = await getIsWhiteListed(w);
      dispatch({
        type: ConfigActions.CHECK_ISWHITELISTED_WALLET,
        payload: {
          isWhitelistedWallet: isWhitelisted,
          balance: balance.toString(),
        },
      });

      dispatch({
        type: ConfigActions.CONNECT_WALLET,
        payload: {
          wallet: w,
          walletAddress: w.accountHashString ?? "",
          isConnected: true,
          clickRef,
        },
      });

      console.log("csprclick:signed_in", evt);
      setMainPurseFound(false);
      setLoadingBalance(false);
    });

    clickRef?.on("csprclick:signed_out", async (evt) => {
      store.remove("wallet_provider");
      store.remove("cw-pubk");
      dispatch({
        type: ConfigActions.DISCONNECT_WALLET,
        payload: {},
      });
      setNftBalance("0");
      console.log("csprclick:disconnected", evt);
      setWalletLoading(false);
    });
  }, [clickRef?.on]);

  async function onDisconnectWallet(): Promise<void> {
    try {
      if (state.wallet) {
        clickRef.signOut();

        dispatch({ type: ConfigActions.DISCONNECT_WALLET, payload: {} });
        updateNotification({
          type: NotificationType.Info,
          title: "Your wallet is disconnected",
          subtitle: "",
          show: true,
          timeToClose: 3000,
          isOnlyNotification: true,
        });
      }
    } catch (error) {
      log.error(`onDisconnectWallet error: ${error}`);
      updateNotification({
        type: NotificationType.Error,
        title: "Error disconnecting wallet",
        subtitle: "",
        show: true,
        timeToClose: 6000,
        isOnlyNotification: true,
      });
    }
  }
  const [themeMode, setThemeMode] = useState<ThemeModeType>(
    ThemeModeType.light
  );

  const topBarSettings: TopBarSettings = {
    onThemeSwitch: () => {
      setThemeMode(
        themeMode === ThemeModeType.light
          ? ThemeModeType.dark
          : ThemeModeType.light
      );
    },
  };

  return (
    <WalletProviderContext.Provider
      value={{
        onConnectWallet,
        onDisconnectWallet,
        walletState: state,
        isConnected,
        setShowConnectionPopup,
        showConnectionPopup,
        openState: isOpenWalletModal,
        handleOpenState: handleWalletModal,
        openWalletConnect: openModal,
        handleOpenWalletConnect,
        nftCount,
        setNftCount,
        nftBalance,
        loadingBalance,
        setLoadingBalance,
        mintCost,
        walletLoading,
      }}
    >
      <div
        id={"app-click"}
        style={{ position: "absolute", visibility: "hidden" }}
      >
        <ClickUI
          themeMode={themeMode}
          rootAppElement={"#app-click"}
          show1ClickModal={false}
          topBarSettings={topBarSettings}
        />
      </div>
      {children}
    </WalletProviderContext.Provider>
  );
};
