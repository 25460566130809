import { Components, Theme } from '@mui/material/styles';

export default function CssBaseline(
  theme: Theme,
): Record<'MuiCssBaseline', Components['MuiCssBaseline']> {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '::-webkit-scrollbar': {
          width: 10,
        },
        '::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 5px ${theme.palette.grey[500]}`,
          borderRadius: 10,
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.palette.primary.main,
          borderRadius: 10,
        },
        body: {
          overflowX: 'hidden',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
  };
}
