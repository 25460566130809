import { NotificationType, SUPPORTED_NETWORKS } from "../../constants";
import { singAndDeployMintNFT } from "commons/deploys/mintNft";
import { casperClient, WalletProviderContext } from "contexts/WalletContext";
import { createContext, useCallback, useContext, useState } from "react";
import { notificationStore } from "store/store";

const MINTER_PACKAGE_HASH =
  "hash-4f6c75d280bb823da4bce3b13569de2acaace0d6e98a527a1ca41eef97b4762d";
export interface MintContext {
  confirmModal: boolean;
  linkExplorer: string;
  progressModal: boolean;
  setProgressModal?: (visible: boolean) => void;
  setConfirmModal?: (visible: boolean) => void;
  setLinkExplorer?: (link: string) => void;
  onMintNFT: () => Promise<boolean>;
}

export const MintProviderContext = createContext<MintContext>({} as never);

export const MintContext = ({ children }: { children: React.ReactNode }) => {
  const { walletState, nftCount, setNftCount } = useContext(
    WalletProviderContext
  );
  // states
  const [progressModal, setProgressModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [linkExplorer, setLinkExplorer] = useState("");
  const [stateHash, setStateHash] = useState<string>("");

  const { updateNotification, dismissNotification } = notificationStore();

  const getLatestRootHash = useCallback(async () => {
    return casperClient.getStateRootHash();
  }, []);

  const getRootHash = useCallback(async () => {
    const rootHash = await getLatestRootHash();
    if (rootHash !== stateHash) {
      setStateHash(rootHash);
    }
  }, [stateHash, walletState?.wallet]);

  // TODO call this function on manual refresh
  const refresh = async () => {
    // si tenemos la wallet y sea manual
    await getRootHash();
  };

  async function onMintNFT() {
    try {
      setProgressModal(true);
      updateNotification({
        type: NotificationType.Loading,
        title:
          "Your transaction is currently processing. Please wait a moment.",
        show: true,
        isOnlyNotification: true,
        closeManually: true,
      });
      const mintCost = await casperClient.getMintCost(nftCount.toString());
      const [deployHash] = await singAndDeployMintNFT(
        casperClient,
        walletState.wallet as never,
        nftCount.toString(),
        MINTER_PACKAGE_HASH,
        mintCost.toString(),
        walletState.gasPriceSelectedForMintNFT || 0
      );
      if (!deployHash) {
        setProgressModal(false);
        updateNotification({
          type: NotificationType.Error,
          title: "The transaction has been canceled.",
          subtitle: "",
          show: true,
          timeToClose: 3000,
          isOnlyNotification: true,
        });
        return false;
      }

      const deployUrl =
        SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`;
      setLinkExplorer(deployUrl);

      updateNotification({
        type: NotificationType.Loading,
        title: "Minting NFT's",
        subtitle: "Please hold on as your APOC NFTs are minting on-chain.",
        show: true,
        isOnlyNotification: true,
        closeManually: true,
        url: deployUrl,
      });

      const result = await casperClient.waitForDeployExecution(deployHash);
      if (result) {
        updateNotification({
          type: NotificationType.Success,
          title: "Minted Successfully",
          subtitle: "Your APOC NFT has been minted.",
          show: true,
          isOnlyNotification: true,
          timeToClose: 5000,
          url: deployUrl,
        });
        setNftCount(1);
      }

      await refresh();
      setProgressModal(false);
      setConfirmModal(true);

      return true;
    } catch (err) {
      setProgressModal(false);
      dismissNotification();
      await refresh();
      updateNotification({
        type: NotificationType.Error,
        title: `${err}`,
        subtitle: "",
        show: true,
        isOnlyNotification: true,
        timeToClose: 5000,
      });
      return false;
    }
  }

  return (
    <MintProviderContext.Provider
      value={{
        setProgressModal,
        setConfirmModal,
        setLinkExplorer,
        onMintNFT,
        confirmModal,
        linkExplorer,
        progressModal,
      }}
    >
      {children}
    </MintProviderContext.Provider>
  );
};
