import { CasperClient, Contracts } from "casper-js-sdk";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";

export class MinterClient {
  private casperClient: CasperClient;

  public contractClient: Contracts.Contract;

  constructor(public nodeAddress: string) {
    this.casperClient = new CasperClient(nodeAddress);
    this.contractClient = new Contracts.Contract(this.casperClient);
  }

  public setContractHash(contractHash: string, contractPackageHash?: string) {
    this.contractClient.setContractHash(contractHash, contractPackageHash);
  }

  //   public nativeMint(
  //     args: NativeMintArgs,
  //     deploySender: CLPublicKey,
  //     keys: Keys.AsymmetricKey[]
  //   ) {
  //     const runtimeArgs = RuntimeArgs.fromMap({
  //       nft_owner: accHashToKey(args.nftOwnerAccountHash),
  //       count: CLValueBuilder.u64(args.nftCount),
  //       minter_package_hash: hashToKey(args.minterPackageHash),
  //       amount: CLValueBuilder.u512(args.amount),
  //     });

  //     return this.contractClient.install(
  //       MINT_SESSION_WASM,
  //       runtimeArgs,
  //       GAS.NATIVE_MINT,
  //       deploySender,
  //       this.networkName,
  //       keys
  //     );
  //   }

  public mintFee(): Promise<BigNumber> {
    return this.contractClient.queryContractData(["mint_fee"]);
  }

  public mintCount(): Promise<BigNumber> {
    return this.contractClient.queryContractData(["mint_count"]);
  }

  public onlyWhitelist(): Promise<boolean> {
    return this.contractClient.queryContractData(["only_whitelist"]);
  }

  public allowMint(): Promise<boolean> {
    return this.contractClient.queryContractData(["allow_mint"]);
  }

  public async isWhitelisted(accountHash: string): Promise<boolean> {
    try {
      const isWhitelisted = await this.contractClient.queryContractDictionary(
        "whitelist_dict",
        accountHash.slice(13)
      );
      return isWhitelisted.data.val.data;
    } catch {
      return false;
    }
  }

  public async getMintCost(count: BigNumberish): Promise<BigNumber> {
    const mintFee = await this.mintFee();
    return mintFee.mul(count);
  }
}
