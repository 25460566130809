import { Components, Theme } from '@mui/material';
import { getBorder } from '../../utils/styles';

export default function Tooltip(theme: Theme): Record<'MuiTooltip', Components['MuiTooltip']> {
	return {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: theme.palette.grey[50],
					color: theme.palette.common.white,
					border: getBorder(theme.palette.primary.main, 1),
				},
			},
		},
	};
}
