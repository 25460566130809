import { ButtonIndicator } from "components/@extended/ButtonIndicator";
import { conciseAddress } from "utils/addresses";
import WalletModal from "components/WalletModal";
import ConnectWallet from "components/ConnectWallet";

export interface PopoverButtonInterface {
  isConnected: boolean;
  accountHashString: string;
  onToggle: () => void;
}

export const PopoverButton = ({
  isConnected,
  onToggle,
  accountHashString = "",
}: PopoverButtonInterface) => {
  return (
    <>
      <WalletModal />
      {isConnected ? (
        <ButtonIndicator onClick={onToggle}>
          {conciseAddress(accountHashString, 5)}
        </ButtonIndicator>
      ) : (
        <ConnectWallet />
      )}
    </>
  );
};
