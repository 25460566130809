import BigNumber from "bignumber.js";
import { CLValueBuilder, RuntimeArgs } from "casper-js-sdk";
import { Client as CasperClient, Wallet } from "../wallet";
import { getWASMUint8Array } from "../../utils/wasms";
import { createRecipientAddress } from "../../utils/keys";
import { hashToKey } from "../../utils";

export const singAndDeployMintNFT = async (
  casperClient: CasperClient,
  wallet: Wallet,
  nftCount: string,
  minterPackageHash: string,
  amount: string,
  gasFee: number
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const publicKey = wallet.publicKey as any;
  const MINT_SESSION_WASM = await getWASMUint8Array();
  return await casperClient.signAndDeployWasm(
    wallet,
    MINT_SESSION_WASM,
    RuntimeArgs.fromMap({
      nft_owner: createRecipientAddress(publicKey),
      count: CLValueBuilder.u64(nftCount),
      minter_package_hash: hashToKey(minterPackageHash),
      amount: CLValueBuilder.u512(amount),
    }),
    new BigNumber(gasFee * 10 ** 9)
  );
};
