import { notificationStore } from "store/store";
import NotificationDialog from "./NotificationDialog";

export const NotificationSystem = () => {
  const { notification, updateNotification } = notificationStore();

  const onClose = () => {
    updateNotification({ ...notification, show: false });
  };

  return (
    <div>
      {notification.show && (
        <NotificationDialog
          title={notification.title}
          subtitle={notification.subtitle}
          type={notification.type}
          onClose={onClose}
          //chargerBar={notification.chargerBar}
          timeToClose={notification.timeToClose}
        />
      )}
    </div>
  );
};
