/* eslint-disable @typescript-eslint/no-explicit-any */
import JSBI from "jsbi";
import numbro from "numbro";
import { BigNumber, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";

import { logError } from "./logs";

export const uriToHttp = (url: string): string => {
  let uri = url;
  if (uri.startsWith("ipfs")) {
    const val = url?.split("//");
    uri = `https://ipfs.io/ipfs/${val[1]}`;
  }
  return uri;
};

export const truncSymbol = (symbol: string, length: number = 5) => {
  return symbol.length > length ? `${symbol.substring(0, length)}...` : symbol;
};

export const sortObjectArray = <T, U>(arr: T[], key: U): T[] => {
  const newArr = arr;
  newArr.sort((a: any, b: any) =>
    a[key]?.toLowerCase()?.localeCompare(b[key]?.toLowerCase())
  );
  return newArr;
};

export const exponentialToDecimal = (
  exponential: string,
  alloCommas: boolean = true
) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i += 1
    ) {
      postfix += "0";
    }
    const addCommas = (text: string) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        if (alloCommas) {
          // eslint-disable-next-line no-param-reassign
          text = `${text.slice(0, textLength - j)},${text.slice(textLength - j, textLength)}`;
        } else {
          // eslint-disable-next-line no-param-reassign
          text = `${text.slice(0, textLength - j)}${text.slice(textLength - j, textLength)}`;
        }
        textLength += 1;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i += 1) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

export const ogTrunc = (val: number): string | number => {
  if (!Number(val)) {
    return 0;
  }
  const newVal = (val * 100 - Math.trunc(val) * 100) / 100;
  if (!newVal) {
    return Math.trunc(val);
  }
  let decimal = 0;
  if (newVal < 0.000001) {
    decimal = 7;
    return parseFloat(val.toString()).toFixed(7);
  }
  if (newVal < 0.00001) {
    decimal = 6;
  } else if (newVal < 0.0001) {
    decimal = 5;
  } else if (newVal < 0.001) {
    decimal = 4;
  } else if (newVal < 0.01) {
    decimal = 3;
  } else if (newVal < 1) {
    decimal = 2;
  } else {
    return Math.trunc(val).toString();
  }
  const truncVal =
    newVal.toString().match(new RegExp(`^-?\\d+(?:.\\d{0,${decimal}})?`)) ??
    [][0];
  return (Math.trunc(val) + parseFloat(truncVal.toString())).toFixed(decimal);
};

export const trunc = (val: number | string, len = 8): string => {
  const numVal = typeof val === "string" ? parseFloat(val) : val;
  let newVal = ogTrunc(numVal).toString();
  if (newVal.includes("e") || newVal.includes("E")) {
    newVal = exponentialToDecimal(val.toString(), false);
  }
  const newVal2 = newVal.split(".");
  if (newVal2[0].length > len) {
    const joinedStr = newVal2[0].slice(0, len);
    const joined = `${joinedStr}...`;
    return joined;
  }
  if (newVal.includes(".")) {
    const splitedVal = newVal.split(".");
    const floatVal = typeof val === "string" ? parseFloat(val) : val;
    if (floatVal < 0.0001) {
      return "<0.0001";
    }
    return [
      splitedVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      splitedVal[1],
    ].join(".");
  }
  return Number(newVal).toLocaleString();
};

export const formatAmount = (
  num: string | BigNumber,
  decimals = 18
): string => {
  try {
    const number = num.toString();
    const nonExponentNum = exponentialToDecimal(number, false);
    // in-case of precision manually convert wei to decimals
    if (nonExponentNum.includes("."))
      return JSBI.divide(
        JSBI.BigInt(number),
        JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
      ).toString();

    return ethers.utils.formatUnits(nonExponentNum, decimals);
  } catch (e) {
    logError("formatAmount", e);
    return "";
  }
};

export const formatDollarAmount = (
  num: number | undefined,
  digits = 2,
  round = true
) => {
  if (num === 0) return "$0.00";
  if (!num) return "-";
  if (num < 0.001 && digits <= 3) {
    return "<$0.001";
  }
  if (num < 0.01 && digits <= 2) {
    return "<$0.01";
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: "M",
      billion: "B",
    },
  });
};

export function capitalize(value?: string) {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export const toFixed = (_amount: string, decimals: number): string => {
  // eslint-disable-next-line prefer-template
  const regex = new RegExp("^-?\\d+(?:.\\d{0," + decimals + "})?");
  const nonExpAmount = exponentialToDecimal(_amount, false);
  const amount =
    nonExpAmount.toString().split(".").length > 1
      ? (nonExpAmount.toString().match(regex) ?? [][0])
      : nonExpAmount.toString();

  return amount ? `${amount.toString()}` : "";
};

export const parseAmount = (num: string, decimals = 18): string => {
  try {
    return parseUnits(toFixed(num || "0", decimals), decimals).toString();
  } catch (e) {
    logError("parseAmount", e);
    return "";
  }
};

export function formatPercent(percent: string | undefined) {
  if (!percent) return "-";

  return `${Number(percent).toFixed(2)}%`;
}
