import { Components } from '@mui/material/styles';

export default function Paper(): Record<'MuiPaper', Components['MuiPaper']> {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  };
}
