import { Components, Theme } from '@mui/material/styles';

export default function Skeleton(theme: Theme): Record<'MuiSkeleton', Components['MuiSkeleton']> {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
  };
}
