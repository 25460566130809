import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "assets/svgs/close.svg";
import DialogTitle from "@mui/material/DialogTitle";
import MuiIconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";

import generateId from "utils/generateId";
import { getBorder } from "utils/styles";
import { StackRow } from "components/Layout";

interface Props extends DialogProps {
  onClose?: () => void;
  title: string;
  renderRightIcon?: ReactNode;
  rightIconHandler?: () => void;
}

const DialogHeader = styled(StackRow)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  // display: 'grid',
  // columnGap: 20,
  // gridTemplateColumns: '45px 1fr 45px',
  alignItems: "center",
  "& h2": {
    padding: "16px 24px 16px 6px",
  },
  "& .MuiIconButton-root": {
    padding: theme.spacing(1),
    borderRadius: "50%",
    border: "none",
    // position: 'absolute',
    // right: 0,
    // top: theme.spacing(-1),
    // zIndex: zIndex.modal,
  },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  fontSize: "0.2rem",
  background: theme.palette.grey[200],
  border: getBorder(theme.palette.grey[300]),
  padding: theme.spacing(0.4, 0),
  width: "fit-content",
  borderRadius: theme.shape.borderRadius * 2,
  "& svg": {
    height: "1rem",
  },
  "&:hover": {
    color: theme.palette.primary.light,
    border: getBorder(theme.palette.primary.light),
  },
}));

function Dialog({ children, title, onClose, ...rest }: Props) {
  return (
    <MuiDialog {...rest} onClose={onClose} maxWidth="sm">
      <DialogHeader>
        {/* {renderRightIcon ? (
          <IconButton
            color="secondary"
            aria-label="right-button"
            id={generateId(title, 'right')}
            onClick={() => rightIconHandler?.()}
          >
            {renderRightIcon}
          </IconButton>
        ) : (
          <Box />
        )} */}
        <DialogTitle
          textAlign="left"
          variant="h5"
          id={generateId(title, "heading")}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          id={generateId(title, "close")}
          onClick={() => onClose?.()}
        >
          <img src={CloseIcon} alt="close" />
        </IconButton>
      </DialogHeader>
      <DialogContent dividers>{children}</DialogContent>
    </MuiDialog>
  );
}

export default Dialog;
