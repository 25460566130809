import {
  Box,
  Divider,
  Drawer,
  Grid2,
  List,
  ListItem,
  Stack,
  styled,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import mainLogo from "assets/main-logo.png";
import { routes, ROUTES } from "constants/routing";
import { Link } from "react-router-dom";
import { StackRow } from "components/Layout";
import { ButtonConnectionOver } from "components/ButtonConnectionOver";
import { useState } from "react";
import ConnectWallet from "components/ConnectWallet";

const GridContainer = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  margin: "0 auto",
  background: theme.palette.background.paper,
}));

const Navbar = styled(StackRow)(({ theme }) => ({
  justifyContent: "flex-start",
  marginLeft: "60px !important",
  "& a": {
    color: theme.palette.common.white,
    margin: "0 16px !important",
    fontSize: "1rem",
    textTransform: "uppercase",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "240px",
  },
}));

const MenuIconButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

export default function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  console.log("isMobile", isMobile);
  console.log("mobileOpen", mobileOpen);

  const handleDrawerToggle = (newOpen: boolean) => () => {
    setMobileOpen(newOpen);
  };

  const DrawerList = (
    <Box
      role="presentation"
      onClick={handleDrawerToggle(false)}
      sx={{ textAlign: "center" }}
    >
      <Box sx={{ my: 2 }}>
        <ButtonConnectionOver />
      </Box>
      <Divider />
      <List>
        {routes.map((item) => (
          <ListItem key={item.to}>
            <Link to={item.to} style={{ textTransform: "uppercase" }}>
              {item.label}
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <GridContainer container alignItems="center">
      <Grid2 size={{ xs: 12, md: 6 }}>
        <StackRow ml={2} justifyContent={isMobile ? "center" : "space-between"}>
          <Link to={ROUTES.LANDING}>
            <img src={mainLogo} alt="logo" width={241} height={64} />
          </Link>
          {!isMobile && (
            <Navbar>
              {routes.map((route) => (
                <Link key={route.label} to={route.to}>
                  {route.label}
                </Link>
              ))}
            </Navbar>
          )}
        </StackRow>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 6 }}>
        {isMobile ? (
          <MenuIconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle(true)}
          >
            <MenuIcon color="primary" />
          </MenuIconButton>
        ) : (
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <ButtonConnectionOver />
          </Stack>
        )}
      </Grid2>

      <StyledDrawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {DrawerList}
      </StyledDrawer>
    </GridContainer>
  );
}
