import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Loadable from "./components/Loadable";
import { lazyRetry } from "./utils/lazyRetry";
import Layout from "./layout";
import { DEFAULT_ROUTE } from "./constants/routing";

const LandingPage = Loadable(
  lazy(() => lazyRetry(() => import("./views/Landing"), "LandingPage"))
);

const Portfolio = Loadable(
  lazy(() => lazyRetry(() => import("./views/Portfolio"), "Portfolio"))
);

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/portfolio",
        element: <Portfolio />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={DEFAULT_ROUTE} replace />,
  },
]);
