import { useContext } from "react";
import { PopoverButton } from "components/PopoverButton";
import { WalletProviderContext } from "contexts/WalletContext";

export const ButtonConnectionOver = () => {
  const { walletState, handleOpenState } = useContext(WalletProviderContext);
  const { isConnected, walletAddress } = walletState;

  return (
    <PopoverButton
      isConnected={isConnected || false}
      accountHashString={walletAddress || ""}
      onToggle={handleOpenState(true)}
    />
  );
};
