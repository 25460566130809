import { useContext, useMemo } from "react";
import { Launch } from "@mui/icons-material";
import { Link, Stack, styled, Typography, Skeleton } from "@mui/material";
import { MotionButtonIndicator } from "components/@extended/ButtonIndicator";
import CopyClip from "components/CopyToClip";
import { StackRow } from "components/Layout";
import { conciseAddress } from "utils/addresses";
import { formatAmount } from "utils/formatting";
import { WalletProviderContext } from "contexts/WalletContext";
import { SUPPORTED_NETWORKS } from "../../constants";

const AddressRow = styled(StackRow)(({ theme }) => ({
  marginTop: theme.spacing(1),
  background: theme.palette.grey[50],
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(0.5),
  alignItems: "center",
  "& img": {
    borderRadius: theme.spacing(2),
  },
}));

const ExplorerLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: ".9rem",
  },
});

const DisconnectBtn = styled(MotionButtonIndicator)({
  width: "80px",
  fontSize: "0.7rem",
});

const BalanceRow = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(1),
  background: theme.palette.grey[50],
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(0.5),
}));

export default function WalletInfo() {
  const { walletState, handleOpenState, onDisconnectWallet, loadingBalance } =
    useContext(WalletProviderContext);
  const { walletAddress, isConnected, balance } = walletState;

  const explorerLink = useMemo(() => {
    return SUPPORTED_NETWORKS.blockExplorerUrl + `/account/${walletAddress}`;
  }, [walletAddress]);

  const handleDisconnect = () => {
    handleOpenState(false)();
    onDisconnectWallet?.();
  };

  return isConnected ? (
    <Stack>
      <Typography variant="h5" mx={1}>
        Wallet Details
      </Typography>
      <AddressRow>
        <StackRow justifyContent="flex-start">
          {walletAddress && (
            <CopyClip
              value={conciseAddress(walletAddress, 10)}
              textToCopy={walletAddress}
            />
          )}
          <ExplorerLink href={explorerLink} target="_blank">
            <Launch />
          </ExplorerLink>
        </StackRow>
        <DisconnectBtn color="primary" onClick={handleDisconnect}>
          Disconnect
        </DisconnectBtn>
      </AddressRow>
      <BalanceRow alignItems="center">
        <Typography variant="h5">Balance</Typography>
        {loadingBalance ? (
          <Skeleton width="100%" height="50px" />
        ) : (
          <Typography variant="h4" my={1}>
            {Number(formatAmount(balance || "0", 9)).toFixed(2)}
            <span style={{ marginLeft: "3px" }}>CSPR</span>
          </Typography>
        )}
      </BalanceRow>
    </Stack>
  ) : null;
}
