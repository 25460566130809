import { Components } from '@mui/material/styles';

export default function TextField(): Record<'MuiTextField', Components['MuiTextField']> {
  return {
    MuiTextField: {
      defaultProps: {
        InputProps: {
          disableUnderline: true,
        },
        hiddenLabel: true,
      },
      styleOverrides: {},
    },
  };
}
