import { Wallet, WalletName } from "../../commons/wallet";
import CSPRClickSDK from "@make-software/csprclick-core-client/sdk";

export type ConfigState = {
  isConnected?: boolean;
  wallet?: Wallet;
  walletAddress?: string;
  isWhitelistedWallet?: boolean;
  balance?: string;
  mainPurse?: string;
  walletSelected?: WalletName;
  gasPriceSelectedForMintNFT?: number;
  wasmGasFee?: number;
  gasFeeHop?: number;
  clickRef?: CSPRClickSDK;
};

export const initialConfigState: ConfigState = {
  isConnected: false,
  wallet: undefined,
  walletAddress: "",
  mainPurse: "",
  balance: "0",
  isWhitelistedWallet: false,
  walletSelected: WalletName.CASPER_SIGNER,
  gasPriceSelectedForMintNFT: parseInt(
    process.env.REACT_APP_GAS_PRICE_FOR_MINTNFT || "40"
  ),
  wasmGasFee: parseInt(process.env.REACT_APP_WASM_GAS_FEE || "40"),
  gasFeeHop: parseInt(process.env.REACT_APP_GAS_FEE_HOP || "40"),
};

export enum ConfigActions {
  CONNECT_WALLET = "CONNECT_WALLET",
  DISCONNECT_WALLET = "DISCONNECT_WALLET",
  SELECT_WALLET = "SELECT_WALLET",
  SELECT_LANGUAGE = "SELECT_LANGUAGE",
  SELECT_VISUAL_MODE = "SELECT_VISUAL_MODE",
  SELECT_SLIPPAGE = "SELECT_SLIPPAGE",
  SELECT_GAS_PRICE = "SELECT_GAS_PRICE",
  SELECT_MAIN_PURSE = "SELECT_MAIN_PURSE",
  CHECK_ISWHITELISTED_WALLET = "CHECK_ISWHITELISTED_WALLET",
}

export type ConfigAction = {
  type: ConfigActions;
  payload: ConfigState;
};

export function ConfigReducer(state: ConfigState, action: ConfigAction) {
  switch (action.type) {
    case ConfigActions.CONNECT_WALLET:
      return {
        ...state,
        isConnected: action.payload.wallet?.isConnected,
        walletAddress: action.payload.wallet?.publicKeyHex,
        //walletSelected: action.payload.wallet.name,
        wallet: action.payload.wallet,
      };
    case ConfigActions.DISCONNECT_WALLET:
      return {
        ...state,
        isConnected: false,
        walletAddress: "",
        mainPurse: "",
        //walletSelected: undefined,
        wallet: undefined,
      };
    case ConfigActions.SELECT_MAIN_PURSE:
      return {
        ...state,
        mainPurse: action.payload.mainPurse,
        balance: action.payload.balance,
        isWhitelistedWallet: action.payload.isWhitelistedWallet,
      };
    case ConfigActions.CHECK_ISWHITELISTED_WALLET:
      return {
        ...state,
        isWhitelistedWallet: action.payload.isWhitelistedWallet,
        balance: action.payload.balance,
      };
    default:
      return { ...state };
  }
}
