export enum ROUTES {
  LANDING = "/",
  PORTFOLIO = "/portfolio",
}

export const routes = [
  {
    label: "Mint",
    to: ROUTES.LANDING,
  },
  {
    label: "Portfolio",
    to: ROUTES.PORTFOLIO,
  },
];

export const DEFAULT_ROUTE = ROUTES.LANDING;

export function routingParameters(route: ROUTES, parameters: string[]) {
  return `${route}/${parameters.join("/")}`;
}
