import {
  Drawer,
  IconButton,
  List,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useContext, useMemo } from "react";
import { getBorder } from "utils/styles";
import Dialog from "components/@extended/Dialog";
import WalletInfo from "components/WalletInfo";
import { WalletProviderContext } from "contexts/WalletContext";

const drawerWidth = 400;
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: "transparent !important",
    boxSizing: "borderBox",
    boxShadow: "none",
    width: `${drawerWidth}px`,
    top: "50px",
  },
  "& .MuiList-root": {
    background: theme.palette.background.paper,
    width: "88%",
    marginLeft: "10%",
    height: "100%",
    borderRadius: theme.spacing(0.8),
    padding: theme.spacing(1, 2),
    paddingTop: theme.spacing(3),
    overflow: "auto",
  },
  "& .MuiMenuItem-root": {
    margin: theme.spacing(1, 0),
    border: getBorder(theme.palette.grey[300], 1),
    background: theme.palette.grey[50],
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiMenuItem-root": {
    margin: theme.spacing(1, 0),
    border: getBorder(theme.palette.grey[300], 1),
    background: theme.palette.grey[50],
  },
}));

const RewindButton = styled(IconButton)({
  position: "absolute",
});

export default function WalletModal() {
  // const {
  //   openState: isOpen,
  //   handleOpenState: handlerModal,
  //   activeAccount,
  // } = useContext(WalletModalContext);
  const {
    walletState,
    openState: isOpen,
    handleOpenState: handlerModal,
  } = useContext(WalletProviderContext);
  const { isConnected } = walletState;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const render = useMemo(() => {
    return (
      <>
        {!sm && (
          <RewindButton onClick={handlerModal(false)}>
            <KeyboardDoubleArrowLeftIcon color="primary" />
          </RewindButton>
        )}
        <List dense>
          {isConnected ? (
            <WalletInfo />
          ) : (
            <>
              <Typography variant="h4">Connect Wallet</Typography>
              <Typography variant="body1">
                Connect your wallet to access all features.
              </Typography>
            </>
          )}
        </List>
      </>
    );
  }, [handlerModal, sm, isConnected]);

  return sm ? (
    <StyledDialog
      open={isOpen}
      title="Connect Wallet"
      onClose={handlerModal(false)}
      aria-labelledby="wallet-connect"
      aria-describedby="connect-your-wallet"
      fullWidth
    >
      {render}
    </StyledDialog>
  ) : (
    <StyledDrawer
      variant="temporary"
      open={isOpen}
      onClose={handlerModal(false)}
      anchor="right"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {render}
    </StyledDrawer>
  );
}
