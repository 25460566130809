import { Theme } from '@mui/material/styles';
import { ColorProps } from '../types/theme';

export function getColors(theme: Theme, color?: ColorProps) {
	switch (color!) {
		case 'secondary':
			return theme.palette.secondary;
		case 'error':
			return theme.palette.error;
		case 'warning':
			return theme.palette.warning;
		case 'info':
			return theme.palette.info;
		case 'success':
			return theme.palette.success;
		default:
			return theme.palette.primary;
	}
}

export function getBorder(color?: string, width = 1, style = 'solid') {
	return `${width}px ${style} ${color}`;
}
