import { Components } from '@mui/material/styles';

export default function ButtonBase(): Record<'MuiButtonBase', Components['MuiButtonBase']> {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  };
}
