import { Components, Theme } from '@mui/material/styles';

export default function Accordion(
  theme: Theme,
): Record<'MuiAccordion', Components['MuiAccordion']> {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
          margin: theme.spacing(2, 0),
        },
      },
    },
  };
}
