import { useClickRef } from "@make-software/csprclick-ui";
import { MotionButtonIndicator } from "components/@extended/ButtonIndicator";
import { WalletProviderContext } from "contexts/WalletContext";
import { useContext } from "react";

export default function ConnectWallet() {
  const clickRef = useClickRef();
  const { onConnectWallet, walletLoading } = useContext(WalletProviderContext);
  return (
    <MotionButtonIndicator
      onClick={() => onConnectWallet?.()}
      color="primary"
      disabled={!clickRef}
      isLoading={walletLoading}
    >
      Connect Wallet
    </MotionButtonIndicator>
  );
}
