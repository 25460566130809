import { ThemeModeType as ThemeMode } from "@make-software/csprclick-ui";

function Theme(mode: ThemeMode) {
  let greyPrimary = [
    "#e9ecf5",
    "#c8d0dd",
    "#a7b1c1",
    "#8692a7",
    "#6e7c93",
    "#576680",
    "#4a5970",
    "#3a465a",
    "#141b22",
    "#141b22",
    "#040b11",
  ];
  let greyAscent = ["#fafafa", "#bfbfbf", "#434343", "#1f1f1f"];
  let greyConstant = ["#fafafb", "#e6ebf1"];

  const contrastText = "#fff";

  const primaryColors = [
    "#54C571",
    "#54C571",
    "#54C571",
    "#FF8D5C",
    "#FF7E47",
    "#FF7033",
    "#54C571",
    "#FF540A",
    "#F54900",
    "#E04300",
    "#CC3D00",
  ];

  let secondaryColors = [
    "#141b22",
    "#49608b",
    "#3c4e71",
    "#2e3d57",
    "#212b3e",
    "#1a2231",
    "#131924",
    "#111826",
    "#191b1e",
    "#0a0d13",
    "#010203",
  ];

  let errorColors = ["#FFE7D3", "#FF805D", "#FF4528", "#DB271D", "#930C1A"];
  let warningColors = ["#FFF6D0", "#FFCF4E", "#FFB814", "#DB970E", "#935B06"];
  let infoColors = ["#DCF0FF", "#7EB9FF", "#549BFF", "#3D78DB", "#1A3D93"];
  let successColors = ["#EAFCD4", "#8AE65B", "#34CC82", "#3DB81E", "#137C0D"];

  if (mode === ThemeMode.dark) {
    greyPrimary = [
      "#040b11",
      "#141b22",
      "#c2c3c5",
      "#3a465a",
      "#4a5970",
      "#576680",
      "#6e7c93",
      "#8692a7",
      "#a7b1c1",
      "#c8d0dd",
      "#e9ecf5",
    ];
    greyAscent = ["#fafafa", "#bfbfbf", "#434343", "#1f1f1f"];
    greyConstant = ["#06080a", "#d3d8db"];

    secondaryColors = [
      "#010203",
      "#0a0d13",
      "#191b1e",
      "#111826",
      "#131924",
      "#1a2231",
      "#212b3e",
      "#2e3d57",
      "#3c4e71",
      "#49608b",
      "#5772a4",
    ];

    errorColors = ["#341d1b", "#b03725", "#dd3f27", "#e9664d", "#fbd6c9"];
    warningColors = ["#342a1a", "#83631a", "#dda116", "#e9ba3a", "#fbefb5"];
    infoColors = ["#202734", "#416fb0", "#4c88dd", "#74a8e9", "#ecf4fb"];
    successColors = ["#1f2e1c", "#449626", "#34CC82", "#74cf4d", "#e3fbd2"];
  }

  const grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16],
  } as const;

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: "#54C571",
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText,
    },
    secondary: {
      lighter: secondaryColors[0],
      100: secondaryColors[1],
      200: secondaryColors[2],
      light: secondaryColors[3],
      400: secondaryColors[4],
      main: secondaryColors[5],
      dark: secondaryColors[6],
      700: secondaryColors[7],
      darker: secondaryColors[8],
      900: secondaryColors[9],
      contrastText: greyColors[800],
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText,
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100],
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText,
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText,
    },
    grey: greyColors,
  };
}

export default Theme;
