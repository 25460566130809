import Dialog from "components/@extended/Dialog";
import { CancelOutlined, Check, GppBad } from "@mui/icons-material";
import { NotificationType } from "../../constants";
import {
  CircularProgress,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { notificationStore } from "store/store";

export interface INotification {
  title: string;
  subtitle?: string;
  type: NotificationType;
  onClose?: () => void;
  chargerBar?: boolean;
  timeToClose?: number;
}

type TProps = {
  color?: string;
};

const Content = styled(Stack)({
  display: "flex",
  alignItems: "flex-start",
});

const TextWrapper = styled(Stack)({
  display: "flex",
  gap: "0.6rem",
  alignItems: "center",
  padding: "15px",
  paddingBottom: "5px",
  width: "100%",
});

const TitleWrapper = styled(Stack)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
  paddingTop: "3px",
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  letterSpacing: "0.02em",
  color: theme.palette.text.primary,
  textAlign: "center",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "0.02em",
  color: theme.palette.text.primary,
  textAlign: "center",
}));

const IconBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "color",
})<TProps>(({ theme, color }) => ({
  color: color || theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  fontSize: "3rem",
  "& .MuiSvgIcon-root": {
    fontSize: "6rem",
  },
}));

const notificationType = {
  [NotificationType.Success]: {
    color: "#1DC90A",
    icon: <Check />,
  },
  [NotificationType.Error]: {
    color: "#E83C3C",
    icon: <CancelOutlined />,
  },
  [NotificationType.Info]: {
    color: "rgba(113, 95, 245, 1)",
    icon: <GppBad />,
  },
  [NotificationType.Loading]: {
    color: "rgba(113, 95, 245, 1)",
    icon: <CircularProgress size={90} />,
  },
};

export default function NotificationDialog({
  title,
  onClose,
  type,
  subtitle,
  timeToClose,
}: INotification) {
  const timeRef = useRef(1);
  const { notification } = notificationStore();

  useEffect(() => {
    if (timeToClose) {
      const interval = setInterval(() => {
        if (timeRef.current === timeToClose) {
          onClose?.();
        }
        timeRef.current += 1;
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [notification.show, timeToClose, onClose, title, subtitle]);

  return (
    <Dialog open title="" onClose={onClose} fullWidth>
      <Content>
        <TextWrapper>
          <IconBox color={notificationType[type].color}>
            {notificationType[type].icon}
          </IconBox>
          <TitleWrapper>
            <Title>{title}</Title>
            {subtitle && (
              <SubTitle>
                {subtitle}
                {notification.url && (
                  <>
                    Click{" "}
                    <Link
                      sx={{ textDecoration: "none" }}
                      href={notification.url}
                      target="_blank"
                    >
                      here
                    </Link>
                    {" to view"}
                  </>
                )}
              </SubTitle>
            )}
          </TitleWrapper>
        </TextWrapper>
      </Content>
    </Dialog>
  );
}
