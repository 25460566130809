import { CircularProgress, Box } from "@mui/material";
import { FC, LazyExoticComponent, Suspense } from "react";
import { styled } from "@mui/material/styles";

const Container = styled(Box)(() => ({
  width: "100vw",
  height: "calc(100vh - 200px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function LoadingProgress() {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
}

function Loadable<P extends object>(Component: LazyExoticComponent<FC>) {
  return function withSuspense(props: P) {
    return (
      <Suspense fallback={<LoadingProgress />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default Loadable;
