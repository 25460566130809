import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";
import { ClickUI } from "@make-software/csprclick-ui";
import Header from "../components/Header";
import Smoke from "../components/Smoke";

export default function Layout() {
  return (
    <Container maxWidth={false} sx={{ padding: "0 !important" }}>
      <Header />
      <ClickUI />
      <Outlet />
      <Smoke />
    </Container>
  );
}
