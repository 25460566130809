import { ReactNode, useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import {
	createTheme,
	ThemeProvider,
	Theme,
	StyledEngineProvider,
	TypographyVariantsOptions,
	ThemeOptions,
} from '@mui/material/styles';
import { Palette } from './palette';
import Typography from './typography';
import ComponentsOverrides from './overrides';
import { ThemeModeType as ThemeMode } from '@make-software/csprclick-ui';

type ThemeCustomizationProps = {
	children: ReactNode;
};

function ThemeCustomization({ children }: ThemeCustomizationProps) {
	const mode = ThemeMode.dark;
	const theme = useMemo<Theme>(() => Palette(mode), [mode]);

	const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(() => Typography(), []);

	const themeOptions: ThemeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1536,
				},
			},
			mixins: {
				toolbar: {
					minHeight: 60,
					paddingTop: 8,
					paddingBottom: 8,
				},
			},
			palette: theme.palette,
			typography: themeTypography,
		}),
		[theme, themeTypography]
	);

	const themes: Theme = createTheme(themeOptions);
	themes.components = ComponentsOverrides(themes);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline enableColorScheme />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default ThemeCustomization;
