import { SmokeScene } from "react-smoke";
import { styled, Box } from "@mui/material";
import bgImg from "assets/backgrounds/bg-1.png";

const SmokeContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  backgroundImage: `
    linear-gradient(to right, rgba(84, 197, 113,0.3), transparent, rgba(84, 197, 113,0.3)), 
    url(${bgImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  zIndex: -1,
  "& > div": {
    position: "absolute",
    top: "50%",
    left: "50%",
    minWidth: "100%",
    minHeight: "100%",
    width: "auto",
    height: "auto",
    transform: "translate(-50%, -50%)",
    opacity: 0.55, // Adjusted for a more noticeable smoke effect
    objectFit: "cover",
    filter: "blur(6px)", // Slightly increased blur for a softer smoke effect
  },
});

export default function Smoke() {
  return (
    <SmokeContainer>
      <SmokeScene
        smoke={{
          density: 40, // Reduced slightly for smoother coverage
          enableRotation: true,
        }}
      />
    </SmokeContainer>
  );
}
