import { Components, Theme } from '@mui/material/styles';
import { getBorder } from '../../utils/styles';

export default function Dialog(theme: Theme): Record<'MuiDialog', Components['MuiDialog']> {
	return {
		MuiDialog: {
			styleOverrides: {
				paper: {
					border: getBorder(theme.palette.grey[200]),
				},
				paperWidthXs: {
					maxWidth: 400,
				},
				paperWidthSm: {
					maxWidth: 450,
				},
				paperWidthMd: {
					maxWidth: 550,
				},
				paperWidthLg: {
					maxWidth: 700,
				},
				paperWidthXl: {
					maxWidth: 900,
				},
			},
		},
	};
}
