import { merge } from 'lodash';
import { Theme } from '@mui/material/styles';

import Button from './Button';
import Dialog from './Dialog';
import ButtonBase from './ButtonBase';
import CssBaseline from './CssBaseline';
import ButtonGroup from './ButtonGroup';
import Popover from './MuiPopover';
import Paper from './MuiPaper';
import Input from './Input';
import InputBase from './InputBase';
import TextField from './TextField';
import FilledInput from './FilledInput';
import Container from './Container';
import Skeleton from './Skeleton';
import Tooltip from './Tooltip';
import Accordion from './Accordian';

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    CssBaseline(theme),
    Paper(),
    Input(),
    InputBase(),
    TextField(),
    FilledInput(theme),
    Popover(),
    ButtonBase(),
    ButtonGroup(theme),
    Dialog(theme),
    Button(theme),
    Skeleton(theme),
    Container(),
    Tooltip(theme),
    Accordion(theme),
  );
}
