/* eslint-disable @typescript-eslint/no-explicit-any */

import { DEV_ENVIRONMENT } from "constants/main";

const logError = (title: string, ...params: any): void => {
  if (DEV_ENVIRONMENT) {
    console.error("ERROR ", title, " -> ", ...params, " -> ", Date.now());
  }
};

const logDebug = (title: string, ...params: any): void => {
  if (DEV_ENVIRONMENT) {
    console.debug(title, " -> ", ...params, " -> ", Date.now());
  }
};

const logMessage = (title: string, ...params: any): void => {
  if (DEV_ENVIRONMENT) {
    // eslint-disable-next-line no-console
    console.log(title, " -> ", ...params, " -> ", Date.now());
  }
};

export { logError, logDebug, logMessage };
