import { Components } from '@mui/material/styles';

export default function Popover(): Record<'MuiPopover', Components['MuiPopover']> {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
  };
}
