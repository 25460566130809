import { alpha, createTheme } from '@mui/material/styles';
import Theme from './theme';
import { ThemeModeType as ThemeMode } from '@make-software/csprclick-ui';

export function Palette(mode: ThemeMode) {
	const paletteColor = Theme(mode);
	return createTheme({
		palette: {
			mode,
			common: {
				black: '#000',
				white: '#fff',
			},
			...paletteColor,
			text: {
				primary: mode === ThemeMode.dark ? alpha(paletteColor.grey[900]!, 0.87) : paletteColor.grey[700],
				secondary: mode === ThemeMode.dark ? alpha(paletteColor.grey[900]!, 0.45) : paletteColor.grey[500],
				disabled: mode === ThemeMode.dark ? alpha(paletteColor.grey[900]!, 0.1) : paletteColor.grey[400],
			},
			action: {
				disabled: paletteColor.grey[300],
			},
			divider: mode === ThemeMode.dark ? alpha(paletteColor.grey[900]!, 0.05) : paletteColor.grey[200],
			background: {
				paper: paletteColor.grey[0],
				default: paletteColor.grey[0],
			},
		},
	});
}
