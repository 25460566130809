import { Components } from '@mui/material/styles';

export default function Container(): Record<'MuiContainer', Components['MuiContainer']> {
  return {
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          maxWidth: '1780px !important',
        },
      },
    },
  };
}
