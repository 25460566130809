import { Components, Theme, alpha } from "@mui/material/styles";

import { getColors } from "../../utils/styles";
import { ButtonVariantProps, ExtendedStyleProps } from "../../types/theme";

interface ButtonStyleProps extends ExtendedStyleProps {
  variant: ButtonVariantProps;
}

function getColorStyle({ variant, color, theme }: ButtonStyleProps) {
  const colors = getColors(theme, color);
  const { main, dark, contrastText, light } = colors;
  const disabledStyle = {
    "&.Mui-disabled": {
      color: alpha(contrastText, 0.3),
      backgroundColor: alpha(main, 0.6),
    },
  };
  switch (variant) {
    case "contained":
      return {
        ...disabledStyle,
        color: color === "primary" ? "#19212E" : contrastText,
        backgroundColor: dark,
        "&:hover": {
          backgroundColor: dark,
        },
      };
    case "outlined":
      return {
        ...disabledStyle,
        borderColor: dark,
        color: color === "primary" ? dark : contrastText,
        backgroundColor: alpha(main, color === "primary" ? 0.1 : 0.6),
        "&:hover": {
          backgroundColor: alpha(main, color === "primary" ? 0.2 : 0.8),
          borderColor: dark,
        },
      };
    case "text":
    default:
      return {
        ...disabledStyle,
        "&:hover": {
          color: light,
          backgroundColor: alpha(light, 0.1),
        },
      };
  }
}

export default function Button(
  theme: Theme
): Record<"MuiButton", Components["MuiButton"]> {
  const iconStyle = {
    "&>*:nth-of-type(1)": {
      fontSize: "inherit",
    },
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: 0,
          "&::after": {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            borderRadius: 0,
            opacity: 0,
            transition: "all 0.5s",
          },

          "&:active::after": {
            position: "absolute",
            borderRadius: 4,
            left: 0,
            top: 0,
            opacity: 1,
            transition: "0s",
          },
        },
        text: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        endIcon: {
          ...iconStyle,
        },
        startIcon: {
          ...iconStyle,
        },
        containedPrimary: getColorStyle({
          variant: "contained",
          color: "primary",
          theme,
        }),
        containedSecondary: getColorStyle({
          variant: "contained",
          color: "secondary",
          theme,
        }),
        containedError: getColorStyle({
          variant: "contained",
          color: "error",
          theme,
        }),
        containedSuccess: getColorStyle({
          variant: "contained",
          color: "success",
          theme,
        }),
        containedInfo: getColorStyle({
          variant: "contained",
          color: "info",
          theme,
        }),
        containedWarning: getColorStyle({
          variant: "contained",
          color: "warning",
          theme,
        }),
        outlinedPrimary: getColorStyle({
          variant: "outlined",
          color: "primary",
          theme,
        }),
        outlinedSecondary: getColorStyle({
          variant: "outlined",
          color: "secondary",
          theme,
        }),
        outlinedError: getColorStyle({
          variant: "outlined",
          color: "error",
          theme,
        }),
        outlinedSuccess: getColorStyle({
          variant: "outlined",
          color: "success",
          theme,
        }),
        outlinedInfo: getColorStyle({
          variant: "outlined",
          color: "info",
          theme,
        }),
        outlinedWarning: getColorStyle({
          variant: "outlined",
          color: "warning",
          theme,
        }),
        textPrimary: getColorStyle({
          variant: "text",
          color: "primary",
          theme,
        }),
        textSecondary: getColorStyle({
          variant: "text",
          color: "secondary",
          theme,
        }),
        textError: getColorStyle({ variant: "text", color: "error", theme }),
        textSuccess: getColorStyle({
          variant: "text",
          color: "success",
          theme,
        }),
        textInfo: getColorStyle({ variant: "text", color: "info", theme }),
        textWarning: getColorStyle({
          variant: "text",
          color: "warning",
          theme,
        }),
      },
    },
  };
}
