import { Network } from "commons/wallet/types";

let CSPRPackageHash;
let networkName: Network;
let casperNode: string;
let minterContractHash: string;
let minterPackageHash: string;

switch (process.env.REACT_APP_NETWORK_KEY) {
  case "casper-testing":
    CSPRPackageHash =
      "hash-0885c63f5f25ec5b6f3b57338fae5849aea5f1a2c96fc61411f2bfc5e432de5a";
    networkName = Network.CASPER_TESTNET;
    casperNode = "https://casper-node-proxy.dev.make.services/";
    minterContractHash =
      "hash-1094f747e47bfe9fbab68c042411c2f381cd834b02515a3433ac0f38ff9677c9";
    minterPackageHash =
      "hash-4c4e1298ca4dc7bb99a36b43bc8ab6333a083fe91b0549dbb4b0e1b004fa1c38";
    break;
  case "integration-test":
    CSPRPackageHash =
      "hash-6b7453749234eb23f6e81aa2fe01b3a8f4bafb94247942a6ac0d77e952978afa";
    networkName = Network.CASPER_INTEGRATION;
    casperNode = "https://casper-node-proxy.dev-integration.make.services/";
    minterContractHash = "";
    minterPackageHash = "";
    break;
  case "casper":
    CSPRPackageHash =
      "hash-c6649901da894d4ac2c77c0ae217190f79cabc8c0c91788ee997f670b8bdd05e";
    networkName = Network.CASPER_MAINNET;
    casperNode = "https://casper-node-proxy.make.services/";
    minterContractHash = "";
    minterPackageHash = "";
    break;
  default:
    CSPRPackageHash =
      "hash-0885c63f5f25ec5b6f3b57338fae5849aea5f1a2c96fc61411f2bfc5e432de5a";
    networkName = Network.CASPER_TESTNET;
    casperNode = "https://casper-node-proxy.dev.make.services/";
    minterContractHash = "";
    minterPackageHash = "";
}

export {
  CSPRPackageHash,
  networkName,
  casperNode,
  minterContractHash,
  minterPackageHash,
};
